html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  position: relative;
  margin: 0;
  font-family: 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Roboto,
    'Helvetica Neue', Helvetica, SimSun, sans-serif;
  /* background-color: var(--neutral-100);
  color: var(--neutral-400); */
  /* 阻止滚动到底部或者顶部的回弹 */
  overscroll-behavior-y: none;
  background: #f9edde url('/images/common/bg.png') no-repeat center center /
    100% 100%;
}

@font-face {
  font-family: XinYiJiXiangSong;
  src: url('fonts/Font.ttf');
}

.font-song {
  font-family: XinYiJiXiangSong, sans-serif;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** 两行省略 **/
.text-overflow-line-2 {
  word-break: break-all;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
/** 三行省略 **/
.text-overflow-line-3 {
  word-break: break-all;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
/** 盒子支持滚动 **/
.box-scroll {
  @apply no-scroll-bar inline-flex w-full overflow-x-auto overflow-y-hidden;
}

/** 谷歌内核下：隐藏滚动条 **/
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.adm-slider {
  height: 18px;
  padding: 4px 10px !important;
  .adm-slider-track-container {
    padding: 0;
  }
  .adm-slider-track {
    background: var(--neutral-300) !important;
    height: 8px;
    border-radius: 100px;
  }
  .adm-slider-fill {
    background: var(--primary) !important;
    height: 8px;
    border-radius: 100px;
  }
  .adm-slider-thumb-container {
    height: 16px;
    width: 16px;
  }
  .adm-slider-thumb {
    border: 3px solid var(--neutral-800) !important;
    background: var(--primary) !important;
    width: 16px;
    height: 16px;
    margin: 0;
    svg {
      display: none;
    }
  }
}

.adm-checkbox.adm-checkbox {
  --font-size: 12px;
  .adm-checkbox-icon {
    border: 2px solid #a88964;
    width: 16px;
    height: 16px;
  }
  &.adm-checkbox-checked .adm-checkbox-icon {
    border-color: var(--primary);
  }
}

.adm-tabs {
  .adm-tabs-header {
    border-bottom: 1px solid var(--neutral-300) !important;
  }

  .adm-tabs-tab-wrapper {
    padding: 0 16px;
  }

  .adm-tabs-tab {
    padding-top: 16px;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    color: var(--neutral-400);
    font-weight: 600;
    &.adm-tabs-tab-active {
      color: var(--neutral-900);
    }
  }
}

.adm-radio {
  .adm-radio-icon {
    width: 16px !important;
    height: 16px !important;
    border: 2px solid var(--neutral-300) !important;
  }
}

.adm-center-popup,
.adm-center-popup-wrap {
  --max-width: 312px !important;
  width: 312px !important;
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
}
