@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    color: var(--primary);
  }
  svg {
    display: inline-block;
  }
}

@layer utilities {
  .transition-all {
    transition: all 0.3s ease;
  }
  .bg-for-primary-text {
    background: rgb(var(--primary-raw) / 0.1);
  }
  .border-rounded {
    @apply px-4;
    @apply py-3;
    @apply rounded-xl;
    @apply border-2;
    @apply border-neutral-300;
    @apply bg-neutral-100;
  }
  .hide-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@layer components {
  .feature-dialog.adm-center-popup-body {
    border-radius: 24px;
    background: url('/images/common/dialog-bg@2x.png') center center;
    background-size: cover;
    background-color: #afa397;
    .adm-dialog-title {
      font-size: 20px;
      line-height: 32px;
      color: var(--primary);
      margin-bottom: 12px;
    }
    .adm-dialog-content {
      padding: 0 16px 16px 16px;
      font-size: 16px;
      line-height: 24px;
      color: #5c4017;
    }
    .adm-dialog-footer .adm-dialog-action-row {
      border-top: 0;
      padding: 0 16px 16px 16px;
      display: block;
      > .adm-dialog-button {
        height: 48px;
        font-size: 20px;
        border-right: none;
        color: #fdecb6;
        padding: 6px 10px 10px;
        > span {
          font-family: XinYiJiXiangSong, sans-serif;
        }
        + .adm-dialog-button {
          margin-top: 8px;
        }
        &.dialog-btn--primary {
          background: url(/images/common/btn-primary-bg@2x.png) center center;
          background-size: cover;
        }
        &.dialog-btn--default {
          background: url(/images/common/btn-default-bg@2x.png) center center;
          background-size: cover;
        }
      }
    }
  }

  .feature-form {
    ::placeholder {
      color: rgba(255, 255, 255, 0.24);
    }
    .adm-list-body {
      border-top: none !important;
      border-bottom: none !important;
      background: none !important;
    }
    .adm-list-body-inner {
      margin-top: 0;
    }
    .adm-list-item {
      border: 2px solid transparent;
      &.adm-form-item-has-error {
        border-color: var(--error) !important;
        .adm-list-item-description {
          display: none;
        }
      }
    }
    .adm-list-item + .adm-list-item {
      border-top: 2px solid transparent;
    }
    .adm-form-item-required-asterisk {
      display: none;
    }
    .adm-input-element {
      font-size: 12px;
      line-height: 20px;
      color: #fff;
    }
    .adm-list-item-content {
      border: none;
    }
    .adm-list-item {
      background: #5c4017;
      border-radius: 48px;
      margin-bottom: 16px;
    }
  }
}
