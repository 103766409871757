:root {
  /* normal */
  --primary-raw: 143 96 6;
  --primary: #8f6006;
  --error-raw: 239 70 111;
  --error: #ef466f;
  --success-raw: 69 179 107;
  --success: #45b36b;
  --secondary-raw: 53 57 69;
  --secondary: rgb(53, 57, 69);
  --secondary-300-raw: 255 214 0;
  --secondary-300: #ffd600;

  /* palette */
  --neutral-50-raw: 0 0 0;
  --neutral-50: #000000;
  --neutral-100-raw: 20 20 22;
  --neutral-100: #141416;
  --neutral-200-raw: 35 38 47;
  --neutral-200: #23262f;
  --neutral-300-raw: 53 57 69;
  --neutral-300: #353945;
  --neutral-400-raw: 119 126 144;
  --neutral-400: #777e90;
  --neutral-500-raw: 177 181 196;
  --neutral-500: #b1b5c4;
  --neutral-600-raw: 230 232 236;
  --neutral-600: #e6e8ec;
  --neutral-700-raw: 244 245 246;
  --neutral-700: #f4f5f6;
  --neutral-800-raw: 252 252 253;
  --neutral-800: #fcfcfd;
  --neutral-900-raw: 255 255 255;
  --neutral-900: #ffffff;

  /* component */
  --color-divider-primary: var(--neutral-600);
  --color-divider-secondary: var(--neutral-400);
}

:root {
  --adm-color-primary: var(--primary);
  --adm-color-success: var(--success);
  /* --adm-color-warning: var(--error); */
  --adm-color-danger: var(--error);
  --adm-border-color: var(--neutral-600);
  --adm-color-weak: var(--neutral-500); /* 用于placeholder */
}
